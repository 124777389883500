// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "./controllers"

import initNav from './src/nav'

document.addEventListener('DOMContentLoaded', () => {
  initNav()
})

